import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui' // 解构引入组件

axios.defaults.baseURL = ''
// // 环境切换
// if (process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = 'https://official.cdhengsu.com'
//   // console.log('开发环境api接口url')
// } else if (process.env.NODE_ENV === 'test') {
//   axios.defaults.baseURL = ''
//   // console.log('测试环境api接口url')
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = 'https://official.cdhengsu.com'
//   // console.log('设置生产环境api接口url')
// }

// 请求超时时间
axios.defaults.timeout = 10000

// post请求头
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 获取token
    const token = window.localStorage.getItem('token')
    // 在请求头中携带token
    config.headers.Authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

let isOut = false

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.code == '403' && !isOut) {
      Message.error({
        message: '登录过期，请重新登录',
        duration: 1000
      })
      isOut = true
      // 清除token
      localStorage.removeItem('token')
      // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
      router.push({
        path: '/login'
      })
    } else {
      return Promise.resolve(response)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 封装get方法 和 post方法
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function getfull(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
